import { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SectionDetailDataType,
  SectionDetailCalloutDataType
} from '../../data/types';
import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import FilterGroup from '../../components/FilterGroup';
import DataSummary from '../../components/DataSummary';
import { useLang } from '../../contexts/LangContext';
import { useSelection } from '../../contexts/SelectionContext';
import { JSON_DATA } from '../../constants';

type SectionDetailProps = {
  currentSectionKey?: string;
  section?: SectionDetailDataType;
  onDownload?: Function;
};

const SectionDetail = ({
  currentSectionKey,
  section,
  onDownload
}: SectionDetailProps) => {
  const langContext = useLang();
  const selectionContext = useSelection();
  const history = useHistory();

  // Check if the region, size, and language settings were set
  const isReady = selectionContext?.id !== '' && langContext?.id !== '';
  if (!isReady) {
    return null;
  }

  // Check if the section and currentSectionKey were set
  if (!section || !currentSectionKey) {
    return null;
  }

  return (
    <div className="section-detail">
      {section && (
        <div className="overlay">
          {section.callouts &&
            section.callouts.map(
              (callout: SectionDetailCalloutDataType, calloutIndex: number) => {
                const calloutStyle: CSSProperties = {
                  left: callout.x,
                  top: callout.y,
                  width: callout.width,
                  backgroundColor: callout.bgColor
                };

                let calloutClassName = 'callout';
                if (callout.isIntro) {
                  calloutClassName = calloutClassName + ' intro';
                }
                if (callout.isSignificant) {
                  calloutClassName = calloutClassName + ' significant';
                }

                if (callout.isGrid) {
                  return (
                    <div
                      className="callout grid"
                      style={calloutStyle}
                      key={`callout-` + calloutIndex}
                    >
                      {callout.title && (
                        <h3>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: callout.title
                            }}
                          ></span>
                        </h3>
                      )}
                      <div className="grid-group">
                        {callout.grid &&
                          callout.grid.map(
                            (gridItem: any, gridIndex: number) => {
                              let gridClassName = 'grid-item';
                              let hasFilter = false;
                              if (
                                gridItem.cta &&
                                gridItem.cta.location === 'download'
                              ) {
                                hasFilter = true;
                                gridClassName = gridClassName + ' download';
                              }
                              return (
                                <div
                                  className={gridClassName}
                                  key={`grid-` + gridIndex}
                                  style={{ height: gridItem.height }}
                                >
                                  <h2
                                    dangerouslySetInnerHTML={{
                                      __html: gridItem.title
                                    }}
                                  ></h2>
                                  <div
                                    className="description"
                                    dangerouslySetInnerHTML={{
                                      __html: gridItem.description
                                    }}
                                  ></div>
                                  {hasFilter && (
                                    <div className="filter">
                                      <FilterGroup />
                                    </div>
                                  )}
                                  {gridItem.cta && (
                                    <div>
                                      <div className="cta-location">
                                        <a href={gridItem.cta.location}>
                                          {gridItem.cta.location}
                                        </a>
                                      </div>
                                      <Button
                                        label={gridItem.cta.label}
                                        detail={gridItem.cta.detail}
                                        disabled={!isReady}
                                        onClick={() => {
                                          if (gridItem.cta) {
                                            if (gridItem.cta.location) {
                                              if (
                                                gridItem.cta.location ===
                                                  'download' &&
                                                onDownload
                                              ) {
                                                onDownload();
                                              } else {
                                                window.open(
                                                  gridItem.cta.location,
                                                  't'
                                                );
                                              }
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={calloutClassName}
                      style={calloutStyle}
                      key={`callout-` + calloutIndex}
                    >
                      {callout.title && (
                        <h3
                          className={callout.isSummary ? 'summary' : ''}
                          dangerouslySetInnerHTML={{
                            __html: callout.title
                          }}
                        ></h3>
                      )}
                      {callout.dataId && (
                        <DataSummary
                          id={callout.dataId}
                          isSummary={callout.isSummary}
                        />
                      )}
                      {callout.description && (
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: callout.description
                          }}
                        ></div>
                      )}
                      {callout.optional && (
                        <div className="optional">
                          <div className="icon" />
                          <div
                            className="tidbit"
                            dangerouslySetInnerHTML={{
                              __html: callout.optional
                            }}
                          ></div>
                        </div>
                      )}
                      {callout.note && (
                        <div
                          className="note"
                          dangerouslySetInnerHTML={{
                            __html: callout.note
                          }}
                        ></div>
                      )}
                      <div style={{ whiteSpace: 'nowrap' }}>
                        {callout.filter &&
                          callout.filter.map(
                            (filterId: string, filterIndex: number) => {
                              let value = '';
                              if (selectionContext && langContext) {
                                if (filterId === 'selection') {
                                  value = selectionContext?.id;
                                } else if (filterId === 'language') {
                                  value = langContext?.id;
                                }
                              }
                              const config = JSON_DATA.filters[filterId];
                              if (config) {
                                return (
                                  <Dropdown
                                    value={value}
                                    config={config}
                                    onChange={(e) => {
                                      if (config.id === 'selection') {
                                        selectionContext?.setSelection({
                                          id: e.target.value,
                                          label:
                                            e.nativeEvent.target[
                                              e.nativeEvent.target.selectedIndex
                                            ].text
                                        });
                                      }
                                      if (config.id === 'language') {
                                        langContext?.setLang({
                                          id: e.target.value,
                                          label:
                                            e.nativeEvent.target[
                                              e.nativeEvent.target.selectedIndex
                                            ].text
                                        });
                                      }
                                    }}
                                    key={`callout-` + filterIndex}
                                  />
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                      </div>
                      {callout.cta && (
                        <Button
                          label={callout.cta.label}
                          detail={callout.cta.detail}
                          disabled={!isReady}
                          onClick={() => {
                            if (callout.cta) {
                              if (callout.cta.location) {
                                history.push(
                                  '/view/' +
                                    callout.cta.location +
                                    history.location.search
                                );
                              }
                            }
                          }}
                        />
                      )}
                    </div>
                  );
                }
              }
            )}
        </div>
      )}
    </div>
  );
};

export default SectionDetail;
