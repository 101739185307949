import { ReactNode, createContext, useReducer, useContext } from 'react';
import { JSON_DATA } from '../constants';

type Action =
  | { type: 'CHANGE_SELECTION'; payload: selectionType }
  | { type: 'DEFAULT'; payload: selectionType };
type State = {
  id: string;
  label: string;
};
type SelectionProviderProps = {
  children: ReactNode;
};
type selectionContextType = {
  id: string;
  label: string;
  setSelection: (selection: selectionType) => void;
};
type selectionType = {
  id: string;
  label: string;
};

const selectionReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'CHANGE_SELECTION': {
      localStorage.setItem('selection', JSON.stringify(action.payload));
      return {
        id: action.payload.id,
        label: action.payload.label
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const SelectionContext = createContext<selectionContextType | undefined>(
  undefined
);

const SelectionProvider = ({ children }: SelectionProviderProps) => {
  const localState = JSON.parse(localStorage.getItem('selection') || '{}');
  // console.log('[SelectionContext] localState', localState);

  // Pull initiate state from dataset
  const initialState = JSON_DATA.filters.selection.default;

  const [state, dispatch] = useReducer(
    selectionReducer,
    localState || initialState
  );

  // Check for empty local
  if (Object.keys(localState).length === 0) {
    // console.log(
    //   '[SelectionContext] localState selection is empty, set initialState'
    // );
    dispatch({ type: 'CHANGE_SELECTION', payload: initialState });
  }

  // Check if localState actually has valid values
  if (Object.keys(localState).length > 0) {
    // console.log('[SelectionContext] has value');
    // Pull values from dataset
    const validValues = JSON_DATA.filters.selection.options;
    const result = validValues.find(({ id }: any) => id === localState.id);
    if (!result) {
      dispatch({ type: 'CHANGE_SELECTION', payload: initialState });
    }
  }

  const setSelection = (newSelection: selectionType) => {
    dispatch({ type: 'CHANGE_SELECTION', payload: newSelection });
  };

  return (
    <SelectionContext.Provider
      value={{
        id: state.id,
        label: state.label,
        setSelection
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

const useSelection = () => useContext(SelectionContext);

export { SelectionProvider, useSelection };
