import { JSON_DATA, JSON_TEXT } from '../../constants';
import Dropdown from '../Dropdown';
import { useLang } from '../../contexts/LangContext';
import { useSelection } from '../../contexts/SelectionContext';
import { FilterOverrideDataType } from '../../data/types';

type FilterGroupProps = {
  hideFilter?: boolean;
  additionalFilter?: string[];
  disabled?: boolean;
  filterOverride?: any;
};

const FilterGroup = ({
  hideFilter = false,
  disabled = false,
  filterOverride = null
}: FilterGroupProps) => {
  const langContext = useLang();
  const selectionContext = useSelection();

  // Return null if hideFilter = true
  if (hideFilter || !langContext) {
    return null;
  }

  const groupLabel = JSON_TEXT[langContext.id].general.filterBy;
  const groupLabelPrint = JSON_TEXT[langContext.id].general.filterByPrint;

  const getDisabled = (
    id: string,
    defaultValue: boolean | undefined
  ): boolean | undefined => {
    if (filterOverride) {
      let selectedFilter = filterOverride.find(
        (thisFilter: FilterOverrideDataType) => {
          return thisFilter.id === id;
        }
      );
      if (selectedFilter) {
        return selectedFilter.disabled;
      }
    } else {
      return defaultValue;
    }
  };

  return (
    <div className="filter-group">
      <h3>
        <span className="normal">{groupLabel}</span>
        <span className="print">{groupLabelPrint}</span>
      </h3>
      <Dropdown
        key="filter-size"
        disabled={getDisabled('selection', disabled)}
        value={selectionContext?.id}
        config={JSON_DATA.filters.selection}
        onChange={(e) => {
          selectionContext?.setSelection({
            id: e.target.value,
            label: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
          });
        }}
      />
    </div>
  );
};

export default FilterGroup;
