import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import download from 'downloadjs';
import { PDFDocument } from 'pdf-lib';
import { SectionDataType, SectionDetailDataType } from '../../data/types';
import { JSON_TEXT } from '../../constants';
import SectionDetail from './SectionDetail';
import SectionNav from '../../components/SectionNav';
import Visualization from '../../components/Visualization';
import SectionDetailNav from '../../components/SectionDetailNav';
import FilterGroup from '../../components/FilterGroup';
import { useLang } from '../../contexts/LangContext';
import { useSelection } from '../../contexts/SelectionContext';

const Section = () => {
  // console.log('[Section] ==============================');
  const defaultSectionKey: string = 'home';
  const defaultDetailKey: number = 1;
  const [sectionKey, setSectionKey] = useState<string | undefined>(undefined);
  const [sectionKeyValid, setSectionKeyValid] = useState<boolean>(false);
  const [detailKey, setDetailKey] = useState<number | undefined>(undefined);
  const [detailKeyValid, setDetailKeyValid] = useState<boolean>(false);
  const [isDisclaimerVisible, setIsDisclaimerVisible] =
    useState<boolean>(false);
  const [isDisclaimerSizeVisible, setIsDisclaimerSizeVisible] =
    useState<boolean>(false);

  const visualizationWidth: number = 1400;
  const visualizationHeight: number = 675;

  const { sectionParam, detailParam } = useParams<{
    sectionParam: string;
    detailParam: string;
  }>();
  const history = useHistory();

  const langContext = useLang();
  const selectionContext = useSelection();

  useEffect(() => {
    setSectionKey(sectionParam);
    if (
      langContext &&
      sectionParam &&
      !JSON_TEXT[langContext.id].pageOrder.includes(sectionParam)
    ) {
      setSectionKeyValid(false);
      console.error(`Error: solution ${sectionParam} does not exist in data`);
      history.push('/view/home' + history.location.search);
    } else {
      // default sectionParam to home if non-existent
      if (!sectionParam) {
        setSectionKey(defaultSectionKey);
        setSectionKeyValid(true);
      } else {
        // if sectionParam is undefined set valid false
        setSectionKeyValid(sectionParam ? true : false);
      }
    }

    setDetailKey(Number(detailParam));
    if (!detailParam) {
      setDetailKeyValid(true);
      setDetailKey(defaultDetailKey);
    } else {
      // Determine if number
      if (!Number(detailParam)) {
        // If not number, force home
        setDetailKeyValid(false);
        history.push('/view/home' + history.location.search);
      } else {
        if (
          langContext &&
          sectionParam &&
          detailParam &&
          JSON_TEXT[langContext.id].pageOrder.includes(sectionParam)
        ) {
          const detailCount =
            JSON_TEXT[langContext.id].pages[sectionParam].sections.length;
          // Determine if number is larger than limit
          if (detailParam > detailCount) {
            setDetailKeyValid(false);
            console.error(`Error: key ${detailParam} is invalid`);
            history.push('/view/home' + history.location.search);
          } else {
            setDetailKeyValid(true);
            setDetailKey(Number(detailParam));
          }
        }
      }
    }
  }, [detailParam, sectionParam, langContext, history]);

  let section: SectionDetailDataType = {};

  // Do not load if langContext is undefined
  if (!langContext) {
    return null;
  }

  const sectionData: SectionDataType | undefined =
    sectionKey && sectionKeyValid
      ? JSON_TEXT[langContext.id].pages[sectionKey]
      : undefined;

  if (
    sectionData &&
    sectionData.sections &&
    sectionKeyValid &&
    sectionKey &&
    detailKeyValid &&
    detailKey
  ) {
    section = sectionData.sections[detailKey - 1];
    if (section.filter) {
      let newFilter: any = [];
      section.filter.forEach((thisFilter: any) => {
        newFilter.push({ group: thisFilter.id, filter: '' });
      });
    }
  }

  // Toggles visibility of disclaimer
  const onDisclaimerHandler = (isVisible: boolean) => {
    setIsDisclaimerVisible(isVisible);
  };

  // Toggles visibility of size disclaimer
  const onDisclaimerSizeHandler = (isVisible: boolean) => {
    setIsDisclaimerSizeVisible(isVisible);
  };

  // Handles download action
  const onDownloadHandler = async () => {
    // console.log('[onDownloadHandler] pressed!');
    const isReady = selectionContext?.id !== '' && langContext?.id !== '';
    if (isReady) {
      const filename = [
        'hpedatastrategy',
        langContext?.id,
        selectionContext?.id
      ];
      const FILE_NAME = filename.join('-') + '.pdf';
      const fileUrl = './pdf/' + FILE_NAME;

      const existingPdfBytes = await fetch(fileUrl).then((res) =>
        res.arrayBuffer()
      );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.setLanguage(langContext.id);
      pdfDoc.setTitle(JSON_TEXT[langContext.id].general.title);
      pdfDoc.setSubject(JSON_TEXT[langContext.id].general.subject);
      pdfDoc.setAuthor(JSON_TEXT[langContext.id].general.author);
      pdfDoc.setCreator(JSON_TEXT[langContext.id].general.creator);
      pdfDoc.setProducer(JSON_TEXT[langContext.id].general.creator);
      pdfDoc.setModificationDate(new Date());
      const pdfBytes = await pdfDoc.save();
      download(pdfBytes, FILE_NAME, 'application/pdf');
    }
  };

  // Set class name for content to catch inverse situation
  let contentClassName = 'content';
  if (sectionData && sectionData.isInverse) {
    contentClassName = contentClassName + ' inverse';
  }

  // Set default section class name
  let sectionClassName = 'section';

  return (
    <section className={sectionClassName}>
      {sectionKeyValid &&
        sectionData &&
        sectionData.sections &&
        sectionKey &&
        detailKey && (
          <div className={contentClassName}>
            <header>
              {sectionData.logo && <div className="primary-logo" />}
              <div>
                {sectionKey === 'home' ? (
                  <h2 className="default">{sectionData.subtitle}</h2>
                ) : (
                  <h2
                    onClick={() => {
                      history.push('/view/home' + history.location.search);
                    }}
                  >
                    {sectionData.subtitle}
                  </h2>
                )}
                <h1>{sectionData.title}</h1>
              </div>
              <SectionDetailNav
                currentSectionKey={sectionKey}
                currentDetailKey={detailKey}
                sections={sectionData.sections}
              />
            </header>
            <article className="main">
              <div className="restricted-group">
                <div className="filter">
                  <FilterGroup
                    hideFilter={section.hideFilter}
                    additionalFilter={section.filter}
                    filterOverride={section.filterOverride}
                  />
                </div>
                <SectionDetail
                  currentSectionKey={sectionKey}
                  section={section}
                  onDownload={onDownloadHandler}
                />
              </div>
              <div className="chart">
                <Visualization
                  id={section.id}
                  width={visualizationWidth}
                  height={visualizationHeight}
                  onDisclaimerToggle={onDisclaimerHandler}
                  onDisclaimerSizeToggle={onDisclaimerSizeHandler}
                />
              </div>
            </article>
            <footer>
              <a href="https://hpe.com" target="_blank" rel="noreferrer">
                <div className="secondary-logo" />
              </a>
              <SectionNav currentSection={sectionKey} />
              <div className="footnote">
                {sectionData.footer}
                <div className="disclaimer">
                  {isDisclaimerSizeVisible && section.disclaimerSize && (
                    <div>{section.disclaimerSize}</div>
                  )}
                  {isDisclaimerVisible && section.disclaimer && (
                    <div>{section.disclaimer}</div>
                  )}
                </div>
              </div>
            </footer>
          </div>
        )}
      {section.hideBg === false && section.bg && (
        <div
          className="bg"
          style={{ backgroundImage: `url(${section.bg})` }}
        ></div>
      )}
    </section>
  );
};

export default Section;
