import { GraphProps } from '../../data/types';
import { JSON_TEXT } from '../../constants';
import {
  VictoryBar,
  VictoryContainer,
  VictoryStack,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryScatter,
  VictoryAxis,
  VictoryTooltip
} from 'victory';
// import GraphEmbeddedListLabel from './GraphEmbeddedListLabel';
import { useLang } from '../../contexts/LangContext';
import { getTypeface } from '../../utils/style';

type thisProps = {
  content: GraphProps;
};

const Graph = ({ content }: thisProps) => {
  const {
    id,
    type,
    data,
    padding,
    domain,
    domainPadding,
    additional,
    labelContent,
    labelSettings,
    style
  } = content;

  const langContext = useLang();
  if (!langContext) {
    return null;
  }

  // Established the correct JSON_TEXT for language pull
  const chartLabels = JSON_TEXT[langContext?.id].chartLabels;

  // Load in correct Typeface
  const TYPEFACE = getTypeface(langContext?.id);

  if (data) {
    if (Object.keys(data).length === 0) {
      return null;
    } else {
      // console.log('  [Graph] id', id, content, visualizationFilter);
    }
  }

  // Temp second version
  const getLabelStack: any = (datum: any) => {
    const label = datum.tip;
    const newTip = Math.round(datum.y);
    if (datum.y === 0) {
      return [];
    }
    // Determine if label is string
    if (typeof label === 'string') {
      let labelArray = label.split('\n');
      if (labelArray.length > 1) {
        return [labelArray[0], labelArray[1], newTip + '%'];
      } else {
        return [labelArray[0], newTip + '%'];
      }
    } else {
      return ['', ''];
    }
  };

  const animationDuration: number = 500;
  const loadAnimationDuration: number = 250;
  const labelStyleStacked = [
    {
      ...TYPEFACE.body,
      fill: '#000000'
    },
    {
      ...TYPEFACE.headerXL,
      fill: '#ffffff'
    }
  ];
  const labelStyleTitle = [
    {
      ...TYPEFACE.bodyM,
      fill: '#525252'
    }
  ];

  let graphDataset: any = [];

  // Custom data preparation for barRealities
  if (type === 'maturity') {
    // Helper format association
    let formatAssociation: any = {
      l1: {
        tip: chartLabels.level1,
        color: '#32dac8',
        altColor: '#e1e1e1'
      },
      l2: {
        tip: chartLabels.level2,
        color: '#7ff9e2',
        altColor: '#aeaeae'
      },
      l3: {
        tip: chartLabels.level3,
        color: '#c6c6c2',
        altColor: '#e1e1e1'
      },
      l4: {
        tip: chartLabels.level4,
        color: '#c140ff',
        altColor: '#d4d4d4'
      },
      l5: {
        tip: chartLabels.level5,
        color: '#7630ea',
        altColor: '#e2e2e2'
      }
    };

    const thisData = data;

    // Iterate through content array
    for (const contentIndex in thisData.content) {
      const thisContent = thisData.content[contentIndex];

      // Create new result
      let thisResult: any = {};
      thisResult.tip = formatAssociation[thisContent.id].tip;
      thisResult.x = thisData.x;
      thisResult.y = thisContent.y;
      thisResult.color = formatAssociation[thisContent.id].color;
      thisResult.altColor = formatAssociation[thisContent.id].altColor;

      graphDataset.push([thisResult]);
    }
    // console.log('[Graph] maturity graphDataset', graphDataset);
  }

  const CustomBackground = (props?: any) => {
    // console.log('props', props);
    const { image, y } = props;
    const defaultImage = 'blank.png';
    if (!image) {
      return <image href={`/assets/images/${defaultImage}`} />;
    }
    // return <image href={'/assets/images/about1.png'} {...props} />;
    return <image href={`/assets/images/${image}`} y={y} />;
  };

  return (
    <div className="graph" id={id} style={style}>
      {/* dimension */}
      {type === 'dimension' && (
        <VictoryChart
          horizontal
          domain={domain}
          padding={padding}
          domainPadding={{ x: 50 }}
          width={Number(style?.width)}
          height={Number(style?.height)}
          minDomain={{ y: 0 }}
          maxDomain={{ y: 5 }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
          style={{ background: {} }}
          backgroundComponent={
            <CustomBackground
              image={additional.backgroundImage}
              y={additional.backgroundY}
            />
          }
        >
          <VictoryAxis
            offsetX={190}
            orientation="left"
            invertAxis={true}
            style={{
              axis: { stroke: '#999' },
              tickLabels: {
                textTransform: 'uppercase',
                fontSize: ({ text }) => {
                  if (text === additional.section) {
                    return 24;
                  } else {
                    return 18;
                  }
                },
                fontFamily: ({ text }) => {
                  if (text === additional.section) {
                    return 'MetricHPEBold';
                  } else {
                    return 'MetricHPERegular';
                  }
                }
              }
            }}
            // tickFormat={(t) => `${Math.round(t)}`}
            // tickLabelComponent={<VictoryLabel dy={40} />}
          />
          <VictoryAxis
            dependentAxis
            label={chartLabels.maturityLevel}
            offsetY={40}
            orientation="top"
            style={{
              axis: { stroke: '#999' },
              axisLabel: {
                // ...TYPEFACE.bodyM // Odd this was not allowe4d
                fontSize: 16,
                padding: 10,
                textTransform: 'uppercase',
                fontFamily: 'MetricHPERegular'
              },
              tickLabels: { display: 'none' }
            }}
            tickFormat={(t) => `${Math.round(t)}`}
            // tickLabelComponent={<VictoryLabel dy={-10} />}
          />
          <VictoryAxis
            dependentAxis
            orientation="top"
            style={{
              axis: { display: 'none' },
              axisLabel: { display: 'none' },
              tickLabels: { ...TYPEFACE.bodyM },
              grid: {
                stroke: '#999',
                strokeWidth: 1,
                strokeDasharray: ({ tick }) => (tick >= 5 ? 'none' : '2, 4')
              }
            }}
            tickFormat={(t) => `${Math.round(t)}`}
            // tickLabelComponent={<VictoryLabel dy={-10} />}
          />
          <VictoryGroup
            data={data}
            animate={{
              duration: animationDuration,
              easing: 'cubicInOut',
              onLoad: { duration: 0 }
            }}
          >
            <VictoryBar
              cornerRadius={{ top: 4, bottom: 4 }}
              barWidth={8}
              style={{
                data: {
                  fill: ({ datum }) => {
                    if (datum.x === additional.section) {
                      return '#01a982';
                    } else {
                      return '#ccc';
                    }
                  }
                }
              }}
            />
            <VictoryScatter
              // size={text === additional.section ? 14 : 12}
              size={8}
              symbol="circle"
              style={{
                data: {
                  fill: ({ datum }) => {
                    if (datum.x === additional.section) {
                      return '#01a982';
                    } else {
                      return '#ffffff';
                    }
                  },
                  stroke: ({ datum }) => {
                    if (datum.x === additional.section) {
                      return '#01a982';
                    } else {
                      return '#ccc';
                    }
                  },
                  strokeWidth: ({ datum }) => {
                    if (datum.x === additional.section) {
                      return 22;
                    } else {
                      return 8;
                    }
                  }
                }
              }}
              labels={({ datum }) => `${datum.y}`}
              labelComponent={
                <VictoryTooltip
                  dy={0}
                  dx={10}
                  constrainToVisibleArea
                  cornerRadius={0}
                  centerOffset={{ x: 0 }}
                  flyoutPadding={10}
                  flyoutStyle={{ stroke: 'transparent', fill: 'transparent' }}
                />
              }
            />
          </VictoryGroup>
        </VictoryChart>
      )}

      {/* maturity: Used in maturity level */}
      {type === 'maturity' && (
        <>
          <VictoryGroup
            domain={domain}
            domainPadding={domainPadding}
            padding={padding}
            width={Number(style?.width)}
            height={Number(style?.height)}
            animate={{
              duration: animationDuration,
              easing: 'cubicInOut'
            }}
            containerComponent={
              <VictoryContainer
                responsive={false}
                width={Number(style?.width)}
                height={Number(style?.height)}
                style={additional.style}
              />
            }
          >
            <VictoryStack
              horizontal
              animate={{
                // animationWhitelist: ['style', 'size', 'opacity', 'data'], // Ideally need to restrict data
                onLoad: { duration: loadAnimationDuration }
              }}
            >
              {graphDataset &&
                graphDataset.map((thisData: any, i: number) => {
                  let thisIsAltColor = additional.isAltColor;
                  let thisLabelSettings = labelSettings;
                  let thisBarWidth = additional.barWidth;
                  let thisLabelStyle = labelStyleStacked;
                  return (
                    <VictoryBar
                      data={thisData}
                      labels={({ datum }) => getLabelStack(datum)}
                      key={'stack-' + i}
                      style={{
                        data: {
                          fill: ({ datum }) => {
                            let newColor = datum.color;
                            if (thisIsAltColor) {
                              newColor = datum.altColor;
                            }
                            // if (additional.isAltColor) {
                            //   newColor = datum.altColor;
                            // }
                            return newColor;
                          }
                        }
                      }}
                      // padding={{ top: 150 }}
                      labelComponent={
                        <VictoryLabel
                          dx={({ datum }) => {
                            const newDx2 = -Math.abs(
                              datum.y * Number(style?.width) * 0.0045
                            );
                            return newDx2;
                          }}
                          style={thisLabelStyle}
                          {...thisLabelSettings}
                        />
                      }
                      barWidth={({ datum }) => {
                        return thisBarWidth;
                      }}
                      // cornerRadius={additional.cornerRadius}
                    />
                  );
                })}
            </VictoryStack>
            {labelContent &&
              labelContent.map((thisLabel, labelIndex) => {
                return (
                  <VictoryLabel
                    key={'label-' + labelIndex.toString()}
                    text={thisLabel.text}
                    x={thisLabel.x}
                    y={thisLabel.y}
                    textAnchor={thisLabel.textAnchor}
                    style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                  />
                );
              })}
          </VictoryGroup>
        </>
      )}

      {/* bar: default test */}
      {type === 'bar' && (
        <VictoryBar data={data} labels={({ datum }) => [datum.x, datum.y]} />
      )}

      {/* label: generic usage */}
      {type === 'label' && (
        <VictoryGroup
          width={Number(style?.width)}
          height={Number(style?.height)}
          animate={{
            duration: animationDuration,
            easing: 'cubicInOut'
          }}
          containerComponent={
            <VictoryContainer
              responsive={false}
              width={Number(style?.width)}
              height={Number(style?.height)}
              style={additional.style}
            />
          }
        >
          {labelContent &&
            labelContent.map((thisLabel, labelIndex) => {
              return (
                <VictoryLabel
                  key={'label-' + labelIndex.toString()}
                  text={thisLabel.text}
                  x={thisLabel.x}
                  y={thisLabel.y}
                  textAnchor={thisLabel.textAnchor}
                  style={thisLabel.style ? thisLabel.style : labelStyleTitle}
                />
              );
            })}
        </VictoryGroup>
      )}
    </div>
  );
};

// One way to hide Axis
// <VictoryAxis
// style={{
//   axis: { stroke: 'transparent' },
//   ticks: { stroke: 'transparent' },
//   tickLabels: { fill: 'transparent' }
// }}
// />

export default Graph;
