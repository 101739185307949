import { JSON_TEXT, JSON_DATA } from '../../constants';
import { useLang } from '../../contexts/LangContext';
import { useSelection } from '../../contexts/SelectionContext';

type DataSummaryProps = {
  id: string;
  isSummary?: boolean;
  onChange?: (any: any) => void;
  disabled?: boolean;
};

const DataSummary = ({ id, isSummary }: DataSummaryProps) => {
  const langContext = useLang();
  const selectionContext = useSelection();

  // Check if contexts is present
  if (!langContext || !selectionContext) {
    return null;
  }

  const translatedLabel = JSON_TEXT[langContext?.id].chartLabels;

  const limit = 5;

  const selectionDefault = JSON_DATA.filters.selection.default.id;
  const overallAverage = JSON_DATA.dataset[selectionDefault][id];
  const industryAverage = JSON_DATA.dataset[selectionContext?.id][id];

  const overallWidth = (overallAverage / limit) * 100;
  const industryWidth = (industryAverage / limit) * 100;

  let isHidden = false;
  if (
    selectionContext.id === 'size_all' ||
    selectionContext.id === 'industry_all'
  ) {
    isHidden = true;
  }

  let classNameHelper = 'data-summary';
  if (isSummary) {
    classNameHelper = `${classNameHelper} summary`;
  }

  return (
    <div className={classNameHelper}>
      <div className="overall">
        <dl>
          <dt>{translatedLabel['overallAverage']}</dt>
          <dd style={{ width: `${overallWidth}%` }}>{overallAverage}</dd>
        </dl>
      </div>
      {!isHidden && (
        <div className="current">
          <dl>
            <dt>{translatedLabel['thisBusinessIndustry']}</dt>
            <dd style={{ width: `${industryWidth}%` }}>{industryAverage}</dd>
          </dl>
        </div>
      )}
    </div>
  );
};

export default DataSummary;
