import ReactDOM from 'react-dom';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { LangProvider } from './contexts/LangContext';
import { SelectionProvider } from './contexts/SelectionContext';
import Compose from './utils/Compose';
import Section from './pages/Section';
import SectionPrint from './pages/Section/SectionPrint';
import './styles/index.scss';

ReactDOM.render(
  <Compose components={[LangProvider, SelectionProvider]}>
    <HashRouter>
      <Switch>
        <Route path="/" exact component={Section} />
        <Route path="/mixable" exact component={SectionPrint} />
        <Route path="/view/:sectionParam?" exact component={Section} />
        <Route
          path="/view/:sectionParam/:detailParam?"
          exact
          component={Section}
        />
      </Switch>
    </HashRouter>
  </Compose>,
  document.getElementById('root')
);
