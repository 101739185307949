import {
  SectionDataFilterType,
  SectionDataFilterOptionsType
} from '../../data/types';
import { JSON_TEXT } from '../../constants';
import { useLang } from '../../contexts/LangContext';

type DropdownProps = {
  config: SectionDataFilterType;
  value?: string;
  onChange?: (any: any) => void;
  disabled?: boolean;
};

const Dropdown = ({
  config,
  value,
  onChange,
  disabled = false
}: DropdownProps) => {
  const langContext = useLang();

  // Check if langContext is present
  if (!langContext) {
    return null;
  }

  const translatedLabel = JSON_TEXT[langContext?.id].chartLabels;

  return (
    <div className="dropdown">
      <strong>{translatedLabel[config.id]}</strong>
      <select
        name={config.id}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {config.options &&
          config.options.map(
            (data: SectionDataFilterOptionsType, index: number) => {
              let isDisabled = false;
              if (data.type === 'disabled') {
                isDisabled = true;
              }
              return (
                <option
                  value={data.id}
                  key={`dd-` + index.toString()}
                  disabled={isDisabled}
                >
                  {translatedLabel[data.id]}
                </option>
              );
            }
          )}
      </select>
    </div>
  );
};

export default Dropdown;
