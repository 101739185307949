import { CSSProperties, useState, useEffect } from 'react';
import Graph from '../Graph';
import { JSON_DATA, JSON_TEXT } from '../../constants';
import { GraphProps } from '../../data/types';
import { useLang } from '../../contexts/LangContext';
import { useSelection } from '../../contexts/SelectionContext';
// import { getTypeface } from '../../utils/style';

type VisualizationProps = {
  id?: string;
  width?: number;
  height?: number;
  onDisclaimerToggle: Function;
  onDisclaimerSizeToggle: Function;
};

const Visualization = ({ id, width, height }: VisualizationProps) => {
  // console.log('[Visualization] ======================', id);
  const langContext = useLang();
  const langDefault = JSON_DATA.filters.language.default.id; // Default language used for chart labels

  const selectionContext = useSelection();
  const selectionDefault = JSON_DATA.filters.selection.default.id;

  const [content, setContent] = useState<any>([{}]);
  const style: CSSProperties = {
    width: width,
    height: height
  };

  useEffect(() => {
    // Returns the dataset content
    const getDataset = ({
      id,
      pageId,
      isRandom,
      selectionId,
      chartLabels
    }: {
      id: string;
      pageId?: string;
      isRandom?: boolean;
      selectionId?: string;
      chartLabels?: any;
    }) => {
      // console.log('[Visualization] getDataset', id, pageId, sizeId, industryId);
      if (selectionId && chartLabels) {
        // const dataset = JSON_DATA.dataset[sizeId][industryId];
        const dataset = JSON_DATA.dataset[selectionId];

        if (
          id === 'strategy_maturity' ||
          id === 'organization_maturity' ||
          id === 'ecosystem_maturity' ||
          id === 'data_maturity' ||
          id === 'ai_maturity' ||
          id === 'operations_maturity'
        ) {
          // return JSON_DATA.dataset[sizeId][industryId][id];
          return JSON_DATA.dataset[selectionId][id];
        }

        let strategyData = dataset['strategy-summary'];
        let organizationData = dataset['organization-summary'];
        let ecosystemData = dataset['ecosystem-summary'];
        let dataData = dataset['data-summary'];
        let aiData = dataset['ai-summary'];
        let operationsData = dataset['operations-summary'];

        // Returns random number from range, formatted for chart
        const getRandom = (min: number, max: number) => {
          const rando = Math.random() * (max - min) + min;
          return Math.round(rando * 10) / 10;
        };

        // Provides random data points for About
        if (isRandom) {
          strategyData = getRandom(1, 3.5);
          organizationData = getRandom(3, 3.8);
          ecosystemData = getRandom(1.5, 2.5);
          dataData = getRandom(1.75, 3);
          aiData = getRandom(2.75, 4.25);
          operationsData = getRandom(1, 3.8);
        }

        const result = [
          {
            x: chartLabels['chart_strategy'],
            y: strategyData
          },
          {
            x: chartLabels['chart_organization'],
            y: organizationData
          },
          {
            x: chartLabels['chart_ecosystem'],
            y: ecosystemData
          },
          {
            x: chartLabels['chart_data-lifecycle'],
            y: dataData
          },
          {
            x: chartLabels['chart_ai-analytics'],
            y: aiData
          },
          {
            x: chartLabels['chart_operations'],
            y: operationsData
          }
        ];
        return result;
      }
    };

    // Established the correct JSON_TEXT for language pull
    const chartLabels =
      JSON_TEXT[langContext ? langContext.id : langDefault].chartLabels;

    // const TYPEFACE = getTypeface(langContext ? langContext.id : langDefault);

    if (id !== undefined) {
      switch (id) {
        case 'about-1':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                isRandom: true,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 80,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 760,
                height: 575,
                top: 0,
                left: 630
              },
              additional: {
                backgroundX: 0,
                backgroundY: 0,
                backgroundImage: 'about1.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'about-2':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                isRandom: true,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 80,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 760,
                height: 575,
                top: 0,
                left: 630
              },
              additional: {
                backgroundX: 0,
                backgroundY: 0,
                backgroundImage: 'about2.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'about-3':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                isRandom: true,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 80,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 760,
                height: 575,
                top: 0,
                left: 630
              },
              additional: {
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'strategy-1':
        case 'strategy-2':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 80,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 760,
                height: 575,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_strategy'],
                backgroundX: 0,
                backgroundY: 90,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'strategy-3':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 300,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 865,
                height: 5,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_strategy'],
                backgroundX: 0,
                backgroundY: 90,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'organization-1':
        case 'organization-2':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 80,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 760,
                height: 575,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_organization'],
                backgroundX: 0,
                backgroundY: 170,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'organization-3':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 300,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 865,
                height: 5,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_organization'],
                backgroundX: 0,
                backgroundY: 170,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'ecosystem-1':
        case 'ecosystem-2':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 80,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 760,
                height: 575,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_ecosystem'],
                backgroundX: 0,
                backgroundY: 250,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'ecosystem-3':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 300,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 865,
                height: 5,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_ecosystem'],
                backgroundX: 0,
                backgroundY: 250,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'data-1':
        case 'data-2':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 80,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 760,
                height: 575,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_data-lifecycle'],
                backgroundX: 0,
                backgroundY: 327,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'data-3':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 300,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 865,
                height: 5,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_data-lifecycle'],
                backgroundX: 0,
                backgroundY: 327,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'ai-1':
        case 'ai-2':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 80,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 760,
                height: 575,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_ai-analytics'],
                backgroundX: 0,
                backgroundY: 405,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'ai-3':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 300,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 865,
                height: 5,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_ai-analytics'],
                backgroundX: 0,
                backgroundY: 405,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'operations-1':
        case 'operations-2':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 80,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 760,
                height: 575,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_operations'],
                backgroundX: 0,
                backgroundY: 485,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        case 'operations-3':
          setContent([
            {
              id: 'chart-1',
              type: 'dimension',
              data: getDataset({
                id: 'chart_id',
                pageId: id,
                selectionId: selectionContext?.id,
                chartLabels
              }),
              padding: {
                top: 300,
                bottom: 0,
                left: 200,
                right: 10
              },
              style: {
                width: 865,
                height: 5,
                top: 0,
                left: 630
              },
              additional: {
                section: chartLabels['chart_operations'],
                backgroundX: 0,
                backgroundY: 485,
                backgroundImage: 'highlight.png',
                style: {
                  // border: '1px dotted green'
                }
              }
            }
          ]);
          break;
        default:
          setContent([]);
      }
    }
  }, [id, langDefault, langContext, selectionContext, selectionDefault]);

  return (
    <div className="visualization" style={style}>
      {content &&
        content.map((graph: GraphProps, index: number) => {
          return <Graph key={'graph-' + index} content={graph} />;
        })}
    </div>
  );
};

export default Visualization;
